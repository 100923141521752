import logo from './tiki-cat-logo.svg';

function Nav() {
  return (
    <header>
      <nav className='navbar navbar-expand-md navbar-dark bg-dark mb-3'>
        <div className='container'>
          <a className='navbar-brand' href='/#'>
            <img src={logo} width='50' height='50' alt='Tiki Cat' />
          </a>
          <button
            className='navbar-toggler'
            type='button'
            data-toggle='collapse'
            data-target='#navbar-menu'
            aria-controls='navbar-menu'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon' />
          </button>
          <div className='collapse navbar-collapse' id='navbar-menu'>
            <ul className='navbar-nav ml-auto'>
              <li className='nav-item'>
                <a className='nav-link' href='/#photos'>
                  Photos
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='/#menu'>
                  Menu
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Nav;
