import range from 'lodash.range';
import shuffle from 'lodash.shuffle';
import padd from 'lodash.padstart';
import { Gallery } from 'react-grid-gallery';
import BackToTop from './BackToTop.js';

function Photos() {
  const photoList = Array.from(range(1, 47)).map(x => ({
    src: `img/photos/thumbnail/lounge-${padd(x, 3, 0)}.jpg`,
    width: 300,
    height: 200
  }));

  const shuffledPhotoList = shuffle(photoList);

  return (
    <>
      <h3 id='photos' className='rounded washout'>
        Photos <BackToTop />
      </h3>
      <div
        className='section rounded washout'
        style={{
          display: 'block',
          minHeight: '1px',
          width: '100%',
          overflow: 'auto'
        }}
      >
        <Gallery images={shuffledPhotoList} enableImageSelection={false} />
      </div>
    </>
  );
};

export default Photos;
