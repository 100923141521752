import BackToTop from './BackToTop';

function DrinkMenuItems({ menu, title, anchor }) {
  const listItems = menu.map((item, index) => (
    <div className='col-md-6 col-lg-4' key={item.name}>
      <div
        className='card mb-2 washout'
        style={{ cursor: 'pointer' }}
      >
        <div
          id={title.substring(0, 1).toLowerCase() + index}
        >
          <img
            src={`img/drinks/${item.photo}`}
            className='card-img-top'
            alt={item.name}
          />
        </div>
        <div className='card-body'>
          <h5 className='card-title'>{item.name}</h5>
          <p className='card-text'>{item.description}</p>
        </div>
      </div>
    </div>
  ));

  return (
    <>
      <h3 className='rounded washout' id={anchor}>
        {title} <BackToTop />
      </h3>
      <div className='row'>{listItems}</div>
    </>
  );
};

export default DrinkMenuItems;
