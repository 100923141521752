import logo from './tiki-cat-logo.svg';

function Intro() {
  return (
    <>
      <div className='jumbotron section washout'>
        <div className='row'>
          <div className='col-md-7'>
            <h1>Tiki Cat Lounge</h1>
            <p className='lead'>The best backyard lounge around.</p>

            <p>
              Our goal is to create an inviting outdoor space where we can spend
              time with friends and family. We provide the atmosphere of a
              lounge, the drinks of a craft cocktail bar, and the conversation
              of the Algonquin Round Table (not really, but a girl can dream).
            </p>
            <p>
              This is our backyard. Not a public place. Private invite only. Do
              we know you?
            </p>

            <a
              className='btn btn-outline-light btn-lg mr-2 mb-2'
              style={{ width: '30%' }}
              href='/#photos'
              role='button'
            >
              Photos
            </a>
            <a
              className='btn btn-outline-light btn-lg mr-2 mb-2'
              style={{ width: '30%' }}
              href='/#menu'
              role='button'
            >
              Menu
            </a>
          </div>

          <div className='col-md-5'>
            <img
              className='img-fluid'
              src={logo}
              height='500'
              width='500'
              alt='Tiki Cat'
            />
          </div>
        </div>
      </div>
      {/* /jumbotron */}
    </>
  );
};

export default Intro;
