import Nav from './Nav';
import Intro from './Intro';
import Photos from './Photos';
import DrinkMenu from './DrinkMenu';
import FuckIt from './FuckIt';
import Footer from './Footer';

function App() {
  return (
    <>
      <Nav />
      <main role='main'>
        <div className='container'>
          <Intro />
          <Photos />
          <DrinkMenu />
          <FuckIt />
          <Footer />
        </div>
      </main>
    </>
  );
};

export default App;
