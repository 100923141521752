import packageInfo from '../package.json';

const version = packageInfo.version;

function Footer() {
  return (
    <footer className='container'>
      <div className='row'>
        <div className='col'>
          This is our backyard, not a public place. Private invite only!
        </div>
        <div class='float-right'>v{version}</div>
      </div>
    </footer>
  );
};

export default Footer;
