import regular from './data/regular-menu.json';
import DrinkMenuItems from './DrinkMenuItems';

function DrinkMenu () {
  const list = regular.filter(item => item.active);

  return (
    <>
      <DrinkMenuItems title='Menu' anchor='menu' menu={list} />
    </>
  );
};

export default DrinkMenu;
