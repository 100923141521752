function BackToTop() {
  return (
    <div className='clearfix'>
      <small className='float-right'>
        <a href='/#'>Back to top</a>
      </small>
    </div>
  );
};

export default BackToTop;
