function FuckIt() {
  return (
    <>
      <h3 className='rounded washout'>
        Wow, you made it all the way down here!
      </h3>
      <a href='https://theoatmeal.com/comics/bad_decision'>
        <img
          src='img/fuck-it/bad-decision.png'
          width='700'
          height='700'
          className='img-fluid mx-auto d-block rounded'
          alt='We love kirkland vodka'
        />
      </a>
    </>
  );
};

export default FuckIt;
